import React from 'react'
import img1 from "../images/about1.jpg"
import img2 from "../images/about2.jpg"

export default function About() {
  return (
    <section className='About' id='About'>
      <div className="container">
        <div className="row">
          <h2><span>О </span>нас</h2>
          <div className="col-lg-6 about-info">
            <p>
            О нас: Компания "Mega Clean" предлагает Вам услуги по стирке ковров, ковровых изделий, одеяла, курпача и шторы в городе Ташкенте по низким ценам с бесплатным вывозом и доставкой. <br />
            <br />
            Стирка ковров и ковровых изделий производится с вывозом на нашу фабрику, с последующей доставкой а пределах Ташкента, а также на месте. <br /> <br />
            Фабрика "Mega Clean" оснащена специальной техникой для чистки, стирки и сушки ковров. <br /> <br />
            Мы используем профессиональные и безопасные моющие средств и выводим загрязнения и пятна ( шампуны из фирм Karpaks, ERA, Lenor). У нас работают специалисты с богатым научно-практическим опытом, способные решать задачи самого сложного уровня. Используемое нами специальное оборудование отличается практичностью и высокой производительностью. 

            </p>
            <img src={img1} alt="about-img" />
          </div>
          <div className="col-lg-6 about-info about-2">
            <img src={img2} alt="about-img" />
            <br />
            <br />
            <p>
              Правильная стирка, применение современной техники, вкупе с широким использованием возможностей химических очистителей, способствует не только повышению качества стирки, но и улучшает эксплуатационные свойства ваших изделий и продлевает сроки использования ковров и  ковролана. <br /> <br />
              И все это за вполне приемлемые цены. Мы ориентированы на оказание услуг широкому слою населения и девизом нашей компании является "Безупречно, гарантия и недорого".
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
